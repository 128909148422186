<template>
  <v-navigation-drawer
    style="position: fixed; max-height: 100%"
    :value="menuStatus"
    :right="true"
    absolute
    temporary
    :color="this.primaryColor"
  >
    <v-list dense nav>
      <template v-for="(item, i) in itemsForList">
        <v-list-item :key="i" link :to="{ name: item.to }">
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <!--        <v-list dense nav>-->
    <!--            <v-list-group-->
    <!--                    v-for="(item, i) in items"-->
    <!--                    :key="i"-->
    <!--                    v-model="item.active"-->
    <!--                    :prepend-icon="item.icon"-->
    <!--                    no-action-->
    <!--            >-->
    <!--                <template v-slot:activator>-->
    <!--                    <v-list-item-content>-->
    <!--                        <v-list-item-title class="white&#45;&#45;text" v-text="item.title"></v-list-item-title>-->
    <!--                    </v-list-item-content>-->
    <!--                </template>-->
    <!--                <template v-if="item.items.length > 0"-->
    <!--                >-->
    <!--                    <v-list-item-->
    <!--                            v-for="child in item.items"-->
    <!--                            :key="child.title"-->
    <!--                    >-->

    <!--                        <v-list-item-content>-->
    <!--                            <router-link :to="{name : child.to}">-->
    <!--                                <v-list-item-title class="white&#45;&#45;text" v-text="child.title"></v-list-item-title>-->
    <!--                            </router-link>-->
    <!--                        </v-list-item-content>-->
    <!--                    </v-list-item>-->
    <!--                </template>-->
    <!--            </v-list-group>-->
    <!--        </v-list>-->
  </v-navigation-drawer>
</template>

<script>
import {
  mdiHome,
  mdiHomeAnalytics,
  mdiInbox,
  mdiImageAlbum,
  mdiCalendar,
  mdiAccountSupervisor,
  mdiApplicationSettings,
  mdiSale,
  mdiInformation,
  mdiAccountGroup,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";

export default {
  name: "SideNav",
  data() {
    return {
      icon: {
        mdiHome,
        mdiHomeAnalytics,
        mdiInbox,
        mdiImageAlbum,
        mdiCalendar,
        mdiAccountSupervisor,
        mdiApplicationSettings,
        mdiSale,
      },
      itemsForList: [
        // {
        //   title: 'الرئيسية',
        //   icon: mdiHome,
        //   to: 'main',
        //   role: ""
        // },
        {
          title: "الرئيسية",
          to: "instructorDashboardMain",
          icon: mdiHomeAnalytics,
          role: "",
        },
        {
          title: "الدورات",
          to: "coursesIndex",
          icon: mdiHomeAnalytics,
          role: "",
        },
        // {
        //   title: 'الطلاب',
        //   icon: mdiSale,
        //   to: 'students',
        //   role: ""
        // },
        // {
        //   title: 'المدربين',
        //   to: 'instructors',
        //   icon: mdiHomeAnalytics,
        //   role: ""
        // },
        // {
        //   title: 'الشهادات',
        //   to: 'main',
        //   icon: mdiInbox,
        //   role: ""
        // },
        // {
        //   title: 'سلايدر',
        //   icon: mdiImageAlbum,
        //   to: 'main',
        //   role: ""
        // },
        // {
        //   title: 'شركاؤنا',
        //   icon: mdiAccountGroup,
        //   to: 'partners',
        //   role: ""
        // },
        // {
        //   title: 'الاسئلة المتكررة',
        //   icon: mdiAccountSupervisor,
        //   to: 'faq',
        //   role: ""
        // },
        // {
        //   title: 'مسار التعلم',
        //   icon: mdiApplicationSettings,
        //   to: 'Training path',
        //   role: ""
        // },
        // {
        //   title: 'الرسائل',
        //   icon: mdiApplicationSettings,
        //   to: 'messages',
        //   role: ""
        // },
        // {
        //   title: 'المشرفين',
        //   icon: mdiApplicationSettings,
        //   to: 'main',
        //   role: ""
        // },
        // {
        //   title: 'عن باكت',
        //   icon: mdiInformation,
        //   to: 'EditAboutUs',
        //   role: ""
        // },
      ],
      // items: [
      //     {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'لوحة التحكم',
      //         to : 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'المبيعات',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'التقارير',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'احصائيات',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'صندوق البريد',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'وسائط',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'القهرس',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'المستخدمينم',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     }, {
      //         action: 'mdi-ticket',
      //         items: [],
      //         title: 'الاعدادات',
      //         to: 'home',
      //         icon: mdiAccountGroup,
      //     },
      // ],
    };
  },
  computed: {
    ...mapState(["DashBoard"]),
    menuStatus() {
      return this.DashBoard.menuIsOpen;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}

/deep/ .theme--light.v-navigation-drawer {
  top: 0 !important;
}

/deep/ .v-list-item__title {
  font-size: 18px !important;
}

/deep/ .theme--light .v-icon {
  color: white !important;
}
</style>
