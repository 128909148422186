<template>
  <v-app>
    <top-nav></top-nav>
    <side-nav></side-nav>
    <v-container class="pa-16">
      <transition name="fade-effect" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </v-container>
  </v-app>
</template>

<script>
import TopNav from "./Components/TopNav";
import SideNav from "./Components/SideNav";
export default {
  name: "DashBoard",
  components: { SideNav, TopNav },

  mounted() {
    if (this.$i18n.locale !== "ar") {
      this.$i18n.locale = "ar";
      localStorage.setItem("lang", this.$i18n.locale);
      window.location.reload();
    }
  },
};
</script>

<style scoped>
.fade-effect-enter {
  opacity: 0;
  transform: translate(20px, -20px);
}

.fade-effect-leave-to {
  opacity: 0;
  transform: translate(20px, -20px);
}
home .fade-effect-enter-active,
.fade-effect-leave-active {
  transition: 0.6s ease-in-out;
}
</style>
